// https://docs.microsoft.com/pt-br/azure/cognitive-services/speech-service/language-support

import * as React from 'react';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: '#663399',
};

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
};
const listItemStyles = {
  fontWeight: '300',
  fontSize: '24px',
  maxWidth: '560px',
};

// $$("#main > div:nth-child(39) > table > tbody > tr > td > span:nth-child(1)").map((s) => s.innerText)
const language = ['Africâner', 'Árabe', 'Bangla', 'Bósnio (latim)', 'Búlgaro', 'Cantonês (tradicional)', 'Catalão', 'Chinês simplificado', 'Chinês (tradicional)', 'Croata', 'Tcheco', 'Dinamarquês', 'Holandês', 'Inglês', 'Estoniano', 'Fijiano', 'Filipino', 'Finlandês', 'Francês', 'Alemão', 'Grego', 'Guzerate', 'Crioulo haitiano', 'Hebraico', 'Híndi', 'Hmong Daw', 'Húngaro', 'Indonésio', 'Irlandês', 'Italiano', 'Japonês', 'canarim', 'Suaíli', 'Klingon', 'Klingon (plqaD)', 'Coreano', 'Letão', 'Lituano', 'Malgaxe', 'Malaio', 'Malaiala', 'Maltês', 'Maori', 'Marati', 'Norueguês', 'Persa', 'Polonês', 'Português (Brasil)', 'Português (Portugal)', 'Panjabi', 'Queretaro Otomi', 'Romeno', 'Russo', 'Samoano', 'Sérvio (cirílico)', 'Sérvio (latino)', 'Eslovaco', 'Esloveno', 'Espanhol', 'Sueco', 'Taitiano', 'Tâmil', 'Télugo', 'Tailandês', 'Tongan', 'Turco', 'Ucraniano', 'Urdu', 'Vietnamita', 'Galês', 'Yucatec Maya'];

// markup
const IdiomasTraducao = () => (
  <main style={pageStyles}>
    <title>Idiomas suportados</title>
    <h1 style={headingStyles}>
      Quais idiomas são suportados?
      <br />
      <span style={headingAccentStyles}>— you just made a Gatsby site! </span>
      <span role="img" aria-label="Party popper emojis">
        🎉🎉🎉
      </span>
    </h1>
    <ul style={listStyles}>
      {language.map((item) => (
        <li key={item} style={{ ...listItemStyles }}>
          <span>
            {item}
          </span>
        </li>
      ))}
    </ul>
    <img
      alt="Gatsby G Logo"
      src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
    />
  </main>
);

export default IdiomasTraducao;
